import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
   BlogCard,
   TitledSection,
   WithLayout,
   // Tab,
   CourseCard,
   GetStartedCard
} from "../../components";
import ideasToReality from "../../assets/images/ideasToReality.png";
import knightBishop from "../../assets/images/knightBishop.jpg";
// import productRoadMap from "../../assets/images/productRoadmap.png";
import courseBg1 from "../../assets/images/homeBanner.png";
// import courseBg2 from "../../assets/images/courseBg2.png";
import "./blog.css";
import { getPublishedBlog } from "../../services";

export const Blog = WithLayout(() => {
   const [blog, setBlog] = useState();

   const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
   ];

   const formatDate = (dateToFormat) => {
      return `${new Date(dateToFormat).getDate()} ${
         months[new Date(dateToFormat).getMonth()]
      }, ${new Date(dateToFormat).getFullYear()}`;
   };

   useEffect(() => {
      const fetchBlog = async () => {
         try {
            const res = await getPublishedBlog();
            setBlog(res);
            console.log(res);
         } catch (error) {
            toast.error("Could not fetch data");
         }
      };
      fetchBlog();
   }, []);
   // const [activeTab, setActiveTab] = useState(0);//
   // const coursesTab = [
   //    {
   //       name: "Accelerators",
   //       action: () => {
   //          setActiveTab(0);
   //       }
   //    },
   //    {
   //       name: "Startups",
   //       action: () => {
   //          setActiveTab(1);
   //       }
   //    },
   //    {
   //       name: "Investors",
   //       action: () => {
   //          setActiveTab(2);
   //       }
   //    },
   //    {
   //       name: "Mentors",
   //       action: () => {
   //          setActiveTab(3);
   //       }
   //    },
   //    {
   //       name: "Partners",
   //       action: () => {
   //          setActiveTab(4);
   //       }
   //    }
   // ];

   // const course = [
   //    { image: courseBg1 },
   //    // { image: courseBg2 },
   //    // { image: courseBg1 },
   //    // { image: courseBg2 },
   //    // { image: courseBg1 },
   //    // { image: courseBg2 }
   // ];
   return (
      <div>
         <Toaster />
         {/* <pre>{JSON.stringify(blog?.data?.data)}</pre> */}
         {blog?.data?.featuredBlogs?.length > 0 && (
            <TitledSection
               title="blog"
               subtitle={
                  <>
                     Featured news
                     <br /> handpicked for you
                  </>
               }
            >
               <BlogCarousel blog={blog} />
            </TitledSection>
         )}

         <TitledSection
            title="explore our collections"
            // subtitle={
            //    <>
            //       A poll of world class <br />
            //       courses at your finger tip
            //    </>
            // }
         >
            {/* <section className="mt-5 mb-5">
               <Tab tabItems={coursesTab} activeTab={activeTab} className="blog-tab" />
            </section> */}

            <section className="row">
               {blog?.data?.blogs.map((item, i) => {
                  return (
                     <div key={i} className="col-lg-4 mb-4">
                        <CourseCard
                           title={item?.title}
                           author={"Knight Ventures"}
                           date={formatDate(item?.publish)}
                           // tags={["Accounting", "Startups"]}
                           image={item?.cover}
                           id={item?.slug}
                        />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our program today!" />
         </section>
      </div>
   );
});

const BlogCarousel = ({ blog }) => {
   return (
      <div
         id="carouselExampleIndicators"
         className="carousel slide blog-carousel"
         data-ride="carousel"
      >
         <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
         </ol>
         <div className="carousel-inner">
            <div className="carousel-item active">
               {/* <img className="d-block w-100" src="..." alt="First slide" /> */}
               <section className="blog-carousel-content">
                  <section className="d-flex align-items-center home-blog-list">
                     {blog?.data?.featuredBlogs.length > 0 &&
                        blog?.data?.featuredBlogs.map((item, i) => {
                           return (
                              <BlogCard
                                 bg={item?.cover}
                                 title={item?.title}
                                 subtitle={item?.body.slice(0, 10)}
                                 // day="22"
                                 // month="Sep"
                              />
                           );
                        })}
                     {/* <BlogCard
                        bg={ideasToReality}
                        title="Application"
                        subtitle="How to Get Seed Funding From Investors"
                        // day="22"
                        // month="Sep"
                     />

                     <BlogCard
                        bg={knightBishop}
                        title="Knight & Bishop"
                        subtitle="Winning With The ongoing Inflation Trend"
                        // day="26"
                        // month="Aug"
                        // width={280}
                     /> */}

                     {/* <BlogCard
                        bg={productRoadMap}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     /> */}
                  </section>
               </section>
            </div>
            <div className="carousel-item">
               <section className="blog-carousel-content">
                  <section className="d-flex align-items-center home-blog-list">
                     {blog?.data?.featuredBlogs.length > 0 &&
                        blog?.data?.featuredBlogs.map((item, i) => {
                           return (
                              <BlogCard
                                 bg={item?.cover}
                                 title={item?.title}
                                 subtitle={item?.body.slice(0, 10)}
                                 // day="22"
                                 // month="Sep"
                              />
                           );
                        })}
                     {/* <BlogCard
                        bg={ideasToReality}
                        title="Application"
                        subtitle="How to Get Seed Funding From Investors"
                        // day="22"
                        // month="Sep"
                     />

                     <BlogCard
                        bg={knightBishop}
                        title="Knight & Bishop"
                        subtitle="Winning With The ongoing Inflation Trend"
                        // day="26"
                        // month="Aug"
                        // width={280}
                     /> */}

                     {/* <BlogCard
                        bg={productRoadMap}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     /> */}
                  </section>
               </section>
            </div>
            <div className="carousel-item">
               <section className="blog-carousel-content">
                  <section className="d-flex align-items-center home-blog-list">
                     {blog?.data?.featuredBlogs.length > 0 &&
                        blog?.data?.featuredBlogs.map((item, i) => {
                           return (
                              <BlogCard
                                 bg={item?.cover}
                                 title={item?.title}
                                 subtitle={item?.body.slice(0, 10)}
                                 // day="22"
                                 // month="Sep"
                              />
                           );
                        })}
                     {/* <BlogCard
                        bg={ideasToReality}
                        title="Application"
                        subtitle="How to Get Seed Funding From Investors"
                        // day="22"
                        // month="Sep"
                     />

                     <BlogCard
                        bg={knightBishop}
                        title="Knight & Bishop"
                        subtitle="Winning With The ongoing Inflation Trend"
                        // day="26"
                        // month="Aug"
                        // width={280}
                     /> */}

                     {/* <BlogCard
                        bg={productRoadMap}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     /> */}
                  </section>
               </section>
            </div>
         </div>
         <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
         >
            <i className="bi bi-chevron-left"></i>

            <span className="sr-only">Previous</span>
         </a>
         <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
         >
            <i className="bi bi-chevron-right"></i>
            <span className="sr-only">Next</span>
         </a>
      </div>
   );
};
