import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import "./header.css";

export const Header = () => {
   const [open, setOpen] = useState(false);
   const location = useLocation();

   return (
      <div className="header">
         <Link to="/">
            <img src={logo} alt="logo" role="button" height={250} width={250} />
         </Link>

         <ul className={`desk-header-list ${open ? "open-side" : ""}`}>
            <li>
               <Link to="/accelerator">
                  {" "}
                  <span className={location.pathname === "/accelerator" ? "active" : ""}>
                     Accelerator
                  </span>
               </Link>
            </li>
            <li>
               <Link to="/startup">
                  {" "}
                  <span className={location.pathname === "/startup" ? "active" : ""}>Startups</span>
               </Link>
            </li>
            <li>
               <Link to="/investor">
                  <span className={location.pathname === "/investor" ? "active" : ""}>
                     Investors
                  </span>{" "}
               </Link>
            </li>
            <li>
               <Link to="/mentor">
                  <span className={location.pathname === "/mentor" ? "active" : ""}>Mentors</span>
               </Link>
            </li>
            <li>
               <Link to="/boosterpartner">
                  <span className={location.pathname === "/boosterpartner" ? "active" : ""}>
                     Partners
                  </span>{" "}
               </Link>
            </li>
            <li>
               <Link to="/blog">
                  <span className={location.pathname === "/blog" ? "active" : ""}>Blog</span>
               </Link>
            </li>
            <li className={`log-in`} role="button">
               {/* Log In */}
               <a href="https://app.knight.ventures" style={{ color: "#2e3192", fontWeight: 700 }}>
                  Log In
               </a>
               {/* <a href="#!" style={{color: "#2e3192",fontWeight: 700}}>Log In</a> */}
            </li>
         </ul>

         <div className={`hams ${open ? "hams-open" : ""}`} onClick={() => setOpen(!open)}>
            <span></span>
            <span></span>
            <span></span>
         </div>
      </div>
   );
};
