import React, { useEffect, useState } from "react";
import { WithLayout, Banner, GetStartedCard } from "../../components";
import banner from "../../assets/images/homeBanner.png";
// import post1 from "../../assets/images/post1.png";
// import post2 from "../../assets/images/post2.png";
import "./singleBlog.css";
import toast, { Toaster } from "react-hot-toast";
import { getBlogDetails } from "../../services";

export const SingleBlog = WithLayout(() => {
   const [blogDetails, setBlogDetails] = useState([]);

   const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
   ];

   const formatDate = (dateToFormat) => {
      return `${new Date(dateToFormat).getDate()} ${
         months[new Date(dateToFormat).getMonth()]
      }, ${new Date(dateToFormat).getFullYear()}`;
   };

   useEffect(() => {
      const fetchBlogDetails = async () => {
         try {
            let str = window.location.pathname;
            let slug = str.split("/")[2];
            const res = await getBlogDetails(slug);
            setBlogDetails(res);
            console.log(res);
         } catch (error) {
            toast.error("Could not fetch data");
         }
      };
      fetchBlogDetails();
      // return () => setPage();
   }, []);

   // const tags = ["Accounting", "Startups"];
   return (
      <div>
         <Toaster />

         <section>
            <Banner
               mainText={
                  <h1>
                     {/* Winning With The ongoing <span>Inflation Trend</span> */}
                     {blogDetails?.data?.title}
                  </h1>
               }
               background={blogDetails?.data?.cover}
               buttonTitle=""
            >
               <div className="w-100 d-flex align-items-center justify-content-between">
                  <section className="d-flex align-items-center blog-info">
                     <p className="mb-0">Knight Ventures</p>
                     <p className="mb-0">
                        <i className="bi bi-dot"></i> {formatDate(blogDetails?.data?.publish)}
                     </p>

                     {/* <p className="mb-0">
                        <i className="bi bi-dot"></i> 7 Mins Read
                     </p> */}
                  </section>

                  {/* <section className="d-flex align-items-center" style={{ columnGap: 11 }}>
                     {tags.length > 0 &&
                        tags.map((tag, i) => {
                           return (
                              <span
                                 className="blog-tag"
                                 style={{ color: i % 2 === 0 ? "#058DC1" : "#1405C1" }}
                              >
                                 {tag}
                              </span>
                           );
                        })}
                  </section> */}
               </div>
            </Banner>
         </section>

         <section className="row single-blog-section">
            <div className="col-lg-4">
               <h6 className="content-header">Content</h6>

               {/* <ul className="content-list">
                  <li>
                     <a href="#plan">How to Win within the Ongoing Inflation Trend</a>
                  </li>
                  <li>
                     <a href="#common">Adjusting your Business to Inflation</a>
                  </li>
               </ul> */}
            </div>

            <div
               className="col-lg-8 px-5 blog-details"
               dangerouslySetInnerHTML={{ __html: blogDetails?.data?.body }}
            >
               {/* <>{blogDetails?.data?.body}</> */}
               {/* <h4 id="plan">WINNING WITH THE ONGOING INFLATION TREND</h4>
               <p>
                  It is evident that inflation has become one of the world's most pressing
                  difficulties, and we all are in struggle to surmount the challenges it poses and
                  channel a path to stay afloat, especially in the area of finance. The good thing
                  is that you can manage it in such a manner that it protects your business's
                  lifespan and may ensure that your business operations costs don’t go over the top.
               </p>
               <p>
                  So, it is common knowledge that inflation is a measure of how much the prices of
                  goods and services have risen over time, often measured year over year. However,
                  it is generally where the understanding regarding inflation ends. While those
                  "increasing prices" represent a national average, how inflation impacts your
                  organization relies on the economic conditions in which it operates prior to
                  higher prices taking effect.
               </p>
               <p>
                  Following the birth of COVID-19, major parts of the world faced a sudden and
                  unprecedented recession, and governments moved in to ensure the safety of
                  employment for their inhabitants and keep the economy moving by constant money
                  printing and sending their citizens stimulus payments. As a result, consumer
                  demand exceeded the different industries' supply capabilities. Several ships were
                  left at docks carrying items that were unable to leave, causing a supply shock.
                  This resulted in hitherto unknown inflation.
               </p>
               <p>
                  The Russia-Ukraine fracas continues to play a role in the current wave of
                  inflation around the world – the West, basically countries in Europe and NATO have
                  sanctioned goods and services originating from Russia. This sent the prices of
                  oil, grains, feed crops for cattle, and fertilizers for growing crops higher as
                  both countries involved in the conflict were major world exporters of these
                  products. Even if the war were to end today, the effects of the sanctions and the
                  war itself would take a while to settle.
               </p>

               <h4 id="common">How can your business adjust to inflation?</h4>
               <p>
                  To mitigate the consequences of such severe inflation, companies have to watch and
                  put a tight control on their expense. Many are concerned that a rise in product
                  costs may result in a major fall in client traffic. To address this, companies
                  need to shift attention to high-profit-margin items that keep the firm afloat
                  rather than making modest improvements to products that don't sell as well.
                  Another option is to lower the costs of certain of your best items in order to
                  draw people who may later purchase your other products.
               </p>

               <p>
                  Streamlining your business procedures is an efficient approach to save money and
                  keep your cash flow in check. Many businesses struggle with the inability to adapt
                  to changes quickly, and because many businesses rely on imported raw materials, it
                  is only natural for them to shift their focus to local products that are optimal
                  and can adequately replace imported raw materials to reduce the cost of production
                  that they incur.
               </p>

               <p>
                  One of the most effective strategies to cut expenses is to identify the supplier
                  that feeds your supplier, and so on. Assess the risk of disruption to your
                  business at each phase of production, identify other sources of supply, and
                  maintain adequate inventories. The days of maintaining lean, just-in-time
                  inventory is long gone.
               </p>

               <p>
                  As product costs rise, it is becoming increasingly important to promote a positive
                  consumer experience. You can't afford to lose consumers right now. According to
                  Forbes, attracting new customers can cost up to five times more than retaining
                  loyal ones, and while increasing engagements can increase profits, you must first
                  determine what keeps your customers coming back and then try to replicate the
                  results in every other product and service that your business offers.
               </p>

               <p>
                  Business owners have to keep up with the trend as well and pay attention to the
                  various global developments that affect business either directly or indirectly.
                  Many countries experiencing an increase in inflation tend to be very irrational in
                  these times for the betterment of their economy and can take drastic actions that
                  have negative impacts.
               </p>

               <p>
                  Inflation is here already and may last longer, hence businesses must expand and
                  initiate collaborations that may end up reshaping the globe over time
               </p>

               <p>
                  In parting words, inflation is not necessarily a bad thing. Businesses can emerge
                  stronger and these times will not be forever.
               </p> */}
            </div>
            <hr />
         </section>

         {/* <section className="single-blog-section ">
            <p className="comment-count mb-4">2 Comments</p>
            <CommentBox
               name="Maria Winner"
               time="Januari 2, 2020 at 10:10 AM"
               comment="what's up with KV"
            />

            <CommentBox
               name="Magdalena Angel"
               time="Januari 2, 2020 at 10:10 AM"
               comment="winner you are right"
            />

            <div>
               <p className="leave-reply-title">Leave a reply</p>
               <p className="leave-reply-subtitle mb-5">Leave your comment below.</p>

               <section className="row">
                  <div className="col-lg-4 mb-4">
                     <input type="text" placeholder="Name" className="w-100 comment-field" />
                  </div>

                  <div className="col-lg-4 mb-4">
                     <input placeholder="Email" type="email" className="w-100 comment-field" />
                  </div>

                  <div className="col-lg-4 mb-4">
                     <input type="text" placeholder="Company" className="w-100 comment-field" />
                  </div>

                  <div className="col-12 mb-4">
                     <label className="d-block comment-label" htmlFor="">
                        Your comment
                     </label>
                     <textarea name="" id="" rows="4" className="w-100 comment-field"></textarea>
                  </div>

                  <div className="col-12">
                     <Button label="Post comment" className="comment-btn" />
                  </div>
               </section>
            </div>
         </section> */}

         {/* <section className="single-blog-section">
            <h5 className="recent-post-title mb-5">Recent Post</h5>

            <div className="row">
               <section className="col-lg-6">
                  <PostCard title="Top 5 Destination in Japan" text="Previous" image={post1} />
               </section>
               <section className="col-lg-6">
                  <PostCard
                     title="Tips for Work-Life Balance Work From Home"
                     text="Next"
                     image={post2}
                  />
               </section>
            </div>
         </section> */}

         <section className="get-started-space">
            <GetStartedCard text="Join our program today!" />
         </section>
      </div>
   );
});

// const CommentBox = ({ name = "", time = "", comment = "", image = "" }) => {
//    return (
//       <div className="comment-box">
//          {image?.length === 0 ? (
//             <span className="image-placeholder"></span>
//          ) : (
//             <img src={image} alt={name} />
//          )}

//          <section>
//             <p className="name">{name}</p>
//             <p className="time">{time}</p>
//             <p className="comment">{comment}</p>
//          </section>
//       </div>
//    );
// };

// const PostCard = ({ title = "", text = "", image = "" }) => {
//    return (
//       <div className="post-card">
//          <p className="text">{text}</p>
//          <img src={image} alt={title} />
//          <p className="title">{title}</p>
//       </div>
//    );
// };
