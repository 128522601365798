import React from "react";
import "./personCard.css";
import twitterIcon from "../../assets/images/twitterRound.svg";
import linkedInIcon from "../../assets/images/linkedIn.svg";

export const PersonCard = ({
   pic = "",
   name = "",
   role = "",
   twitter = "",
   linkedIn = "",
   height = 300,
   contentPosition = "center"
}) => {
   return (
      <div className="person-card">
         <section style={{ height: height }}>
            <img src={pic} alt={name} className="person-image" />
         </section>

         <section style={{ textAlign: contentPosition }}>
            {name.length > 0 && <p className="name">{name}</p>}
            {role.length > 0 && <p className="role">{role}</p>}
            <div>
               <a href={twitter} target={"_blank"} rel="noreferrer">
                  <img src={twitterIcon} alt="twitter" />
               </a>
               <a href={linkedIn} className="ml-1" target={"_blank"} rel="noreferrer">
                  <img src={linkedInIcon} alt="linkedIn" />
               </a>{" "}
            </div>
         </section>
      </div>
   );
};


export const PersonCardWithOutTwitter = ({
   pic = "",
   name = "",
   role = "",
   twitter = "",
   linkedIn = "",
   height = 300,
   contentPosition = "center"
}) => {
   return (
      <div className="person-card">
         <section style={{ height: height }}>
            <img src={pic} alt={name} className="person-image" />
         </section>

         <section style={{ textAlign: contentPosition }}>
            {name.length > 0 && <p className="name">{name}</p>}
            {role.length > 0 && <p className="role">{role}</p>}
            <div>
               {/* <a href={twitter}>
                  <img src={twitterIcon} alt="twitter" />
               </a> */}
               <a href={linkedIn} className="ml-1" target={"_blank"} rel="noreferrer">
                  <img src={linkedInIcon} alt="linkedIn" />
               </a>{" "}
            </div>
         </section>
      </div>
   );
};