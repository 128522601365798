import { request } from "../util";

export const createBlog = async () => {
   try {
      const res = await request.get("/api/blog/create");
      return res.data;
   } catch (error) {
      throw error;
   }
};

export const getBlogs = async () => {
   try {
      const res = await request.get("/api/blog");
      return res.data;
   } catch (error) {
      throw error;
   }
};

export const getPublishedBlog = async () => {
   try {
      const res = await request.get("/api/blog/published?page=1&limit=30");
      return res.data;
   } catch (error) {
      throw error;
   }
};

export const getUnpublishedBlog = async () => {
   try {
      const res = await request.get("/api/blog/unpublished");
      return res.data;
   } catch (error) {
      throw error;
   }
};

export const updateBlog = async () => {
   try {
      const res = await request.post("/api/blog/update");
      return res.data;
   } catch (error) {
      throw error;
   }
};

export const getBlogDetails = async (slug) => {
   try {
      const res = await request.post("/api/blog/details", {slug});
      return res.data;
   } catch (error) {
      throw error;
   }
};

export const deleteBlog = async () => {
   try {
      const res = await request.post("/api/blog/delete");
      return res.data;
   } catch (error) {
      throw error;
   }
};
