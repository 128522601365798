import React from "react";
import "./partnerCard.css";

export const PartnerCard = ({
   icon = "",
   title = "",
   desc = "",
   bene1,
   bene2,
   bene3,
   bene4,
   bene5,
   subhead
}) => {
   return (
      <div>
         <section className="partner-card">
            <img src={icon} alt="icon" className="partner-icon" />
            <h5>{title}</h5>
            <p>{desc}</p>
            <h4>{subhead}</h4>
            <ul className="pt-2">
               {bene1 && <li>{bene1}</li>}
               {bene2 && <li>{bene2}</li>}
               {bene3 && <li>{bene3}</li>}
               {bene4 && <li>{bene4}</li>}
               {bene5 && <li>{bene5}</li>}
            </ul>
         </section>
      </div>
   );
};

export const NoListCard = ({ icon = "", title = "", desc = "" }) => {
   return (
      <div>
         <section className="partner-card">
            <img src={icon} alt="icon" className="partner-icon" />
            <h5>{title}</h5>
            <p>{desc}</p>
         </section>
      </div>
   );
};

export const OneListCard = ({ icon = "", title = "", desc = "", subhead = "", bene = "" }) => {
   return (
      <div>
         <section className="partner-card">
            <img src={icon} alt="icon" className="partner-icon" />
            <h5>{title}</h5>
            <p>{desc}</p>
            <h4>{subhead}</h4>
            <ul className="pt-2">
               <li>{bene}</li>
            </ul>
         </section>
      </div>
   );
};

export const TwoListCard = ({
   icon = "",
   title = "",
   desc = "",
   bene = "",
   bene1 = "",
   subhead = ""
}) => {
   return (
      <div>
         <section className="partner-card">
            <img src={icon} alt="icon" className="partner-icon" />
            <h5>{title}</h5>
            <p>{desc}</p>
            <h4>{subhead}</h4>
            <ul className="pt-2">
               <li>{bene}</li>
               <li>{bene1}</li>
            </ul>
         </section>
      </div>
   );
};

export const PartnerHeader = ({ header }) => {
   return (
      <div>
         <section className="partner-header text-center pt-3">
            <h6>{header}</h6>
         </section>
      </div>
   );
};
