import React, { useState, useEffect, useRef } from "react";
import deco from "../../assets/images/lines-on-program-card.svg";
import "./programCard.css";

export const ProgramCard = ({
   image = "",
   footer = "",
   title = "",
   desc = "",
   fullDesc = "",
   className = "",
   marginB = "",
   more,
   setMore,
   setProgramCardData
}) => {
   const handleClick = () => {
      setProgramCardData({ footer: footer, fullDesc: fullDesc, active: true });
   };
   return (
      <div className={`program-card ${className}`}>
         <section className="program-image">
            <div className="program-more">
               <img src={deco} alt="deco" className="program-deco" />
               <section>
                  <p className="title">{footer}</p>
                  <p className="text">{desc}</p>
               </section>
            </div>
            <img src={image} alt="program" />
            <div className="learn-cta" onClick={handleClick}>
               Learn more <span className="material-icons learn-arrow md-36">chevron_right</span>
            </div>
         </section>
         <div className="program-footer">{footer}</div>
      </div>
   );
};
