import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Header, Footer } from "../index";
import "./layout.css";

const Layout = ({ children }) => {
   const { pathname } = useLocation();
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);
   return (
      <div>
         <Header />
         <section className="layout-child">{children}</section>
         <Footer />
      </div>
   );
};

export const WithLayout = (Component) => {
   return (props) => {
      return (
         <Layout>
            <Component {...props} />
         </Layout>
      );
   };
};
