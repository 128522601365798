import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/footerLogo.svg";
import instagram from "../../assets/images/instagram.svg";
// import youtube from "../../assets/images/youtube.svg";
import linkedin from "../../assets/images/linkedinOutline.svg";
// import privacyDoc from "../../assets/docs/KnightVenturesPrivacyPolicy.pdf";

import "./footer.css";

export const Footer = () => {
   return (
      <div className="kv-footer">
         <section className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 mb-4">
               <Link to="/">
                  <img src={logo} alt="logo" className="mb-5" />
               </Link>
               <p>
                  Knight Venture is an online accelerator and an investment platform that connects
                  African startups with investors
               </p>
            </div>

            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
               <ul>
                  <li>
                     <Link to="/about">About us</Link>
                  </li>
                  <li>
                     <Link to="/about#team">Team</Link>
                  </li>
                  {/* <li>
                     <Link to="/blog">Blog</Link>
                  </li> */}
                  <li>
                     <Link to="/faq">FAQ</Link>
                  </li>
               </ul>
            </div>

            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6 mb-4">
               <ul>
                  <li>
                     <Link to="/startup">Startups</Link>
                  </li>
                  <li>
                     <Link to="/investor">Investors</Link>
                  </li>
                  <li>
                     <Link to="/mentor">Mentors</Link>
                  </li>
                  <li>
                     <Link to="/boosterpartner">Partners</Link>
                  </li>
               </ul>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 mb-4">
               <ul>
                  <li>
                     {/* <Link to="/">Privacy Policy</Link> */}
                     <a href="https://drive.google.com/file/d/1txp-uRAo294f0UiStFnGhvKfeaBiid5m/view?usp=sharing" target="_blank" rel="noreferrer">
                        Privacy Policy
                     </a>
                  </li>
                  <li>
                     <Link to="/">Terms & Condition</Link>
                  </li>
                  <li>
                     <Link to="/">Disclaimer</Link>
                  </li>
               </ul>
            </div>

            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4">
               <ul>
                  <li>
                     <a href="mailto:Info@knight.ventures" className="mail-to">
                        Info@knight.ventures
                     </a>
                  </li>

                  <li>
                     274, Ikorodu Road, Anthony, Lagos State, Nigeria.
                  </li>

                  <li>
                     4652 Ripley Manor Terr, Olney MD 20832 USA.
                  </li>

                  <li>
                     <a href="tel:+234 1 888 0113">+234 1 888 0113</a>
                  </li>

                  <li>
                     <a href="tel:+234 913 444 4860">+234 913 444 4860</a>
                  </li>

                  <li className="d-flex align-items-center">
                     <a href="https://www.instagram.com/knightventures/" target={"_blank"} rel="noreferrer">
                        <img src={instagram} alt="instagram" />
                     </a>
                     <a href="/" className="mx-2">
                        {/* <img src={twitter} alt="twitter" /> */}
                     </a>
                     <a href="https://www.linkedin.com/company/knightnbishopventures/" target={"_blank"} rel="noreferrer">
                        <img src={linkedin} alt="linkedin" />
                     </a>
                  </li>
               </ul>
            </div>
         </section>
      </div>
   );
};
