import React, { useEffect, useState } from "react";
import {
   WithLayout,
   Banner,
   ListItemLine,
   TitledSection,
   RingBullet,
   InfoCard,
   // FeatureInvestor,
   GetStartedCard
} from "../../components";
// import banner from "../../assets/images/startupsBanner.svg";
import banner from "../../assets/images/startBanner.png";
import globalDiscovery from "../../assets/images/globalDiscovery.svg";
import accessGlobal from "../../assets/images/accessGlobal.svg";
import increaseSale from "../../assets/images/increaseSale.svg";
import accessFunding from "../../assets/images/accessFunding.svg";
import accessOpp from "../../assets/images/accessOpp.svg";
import toast, { Toaster } from "react-hot-toast";
// import apple from "../../assets/images/Apple.svg";
// import jeep from "../../assets/images/Jeep.svg";
// import google from "../../assets/images/Google+.svg";
// import nike from "../../assets/images/Nike.svg";
// import tripAdvisor from "../../assets/images/Tripadvisor.svg";
// import github from "../../assets/images/Github.svg";
import { data } from "../../data";
import "./startups.css";
import axios from "axios";
import { request } from "../../util";

export const Startups = WithLayout(() => {
   const [jsonData, setJsonData] = useState([]);
   const renderData = ({ sectionId, columnId, key }) => {
      if (jsonData.length > 0) {
         if (
            jsonData[sectionId] &&
            jsonData[sectionId][columnId] &&
            jsonData[sectionId][columnId]?.data
         ) {
            const data = jsonData[sectionId][columnId]?.data[key];

            return data;
         }

         return "";
      }
   };
   const sectorItem = [
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_1" }),
         color: "#44AB96"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_2" }),
         color: "#32ABE2"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_3" }),
         color: "#2E3192"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_4" }),
         color: "#44AB96"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_5" }),
         color: "#32ABE2"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_6" }),
         color: "#2E3192"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_7" }),
         color: "#44AB96"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_8" }),
         color: "#32ABE2"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_9" }),
         color: "#44AB96"
      },
      {
         text: renderData({ sectionId: 4, columnId: 1, key: "p_text_10" }),
         color: "#44AB96"
      }
   ];
   useEffect(() => {
      const getData = async () => {
         try {
            const data = await request.post("/api/page/details", {
               slug: "startupsam3",
               limit: 30, 
               page: 1,
            });

            setJsonData(JSON.parse(data?.data?.data?.data));
         } catch (error) {
            toast.error("Could not fetch data");
            console.log(error);
         }
      };
      getData();
   }, []);
   return (
      <div>
         <Toaster />
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     {renderData({ sectionId: 0, columnId: 0, key: "title_1" })} <br />
                     <span>{renderData({ sectionId: 0, columnId: 0, key: "title_2" })}</span>
                  </h1>
               }
               subText={renderData({ sectionId: 0, columnId: 0, key: "text" })}
            />
         </section>

         <TitledSection
            title={renderData({ sectionId: 1, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 1, columnId: 0, key: "subtitle" })}
         >
            <section className="d-flex align-items-center mt-5 flex-wrap">
               <ListItemLine
                  num="1"
                  text={renderData({ sectionId: 1, columnId: 1, key: "p_text_1" })}
                  line="up"
               />
               <ListItemLine
                  num="2"
                  text={renderData({ sectionId: 1, columnId: 1, key: "p_text_2" })}
                  line="down"
                  color="#E27007"
               />
               <ListItemLine
                  num="3"
                  text={renderData({ sectionId: 1, columnId: 1, key: "p_text_3" })}
                  line="up"
                  color="#650A9D"
               />
               <ListItemLine
                  num="4"
                  text={renderData({ sectionId: 1, columnId: 1, key: "p_text_4" })}
                  color="#2E3192"
               />
            </section>

            <section className="d-flex align-items-center w-100 flex-wrap">
               <ListItemLine
                  num="5"
                  text={renderData({ sectionId: 1, columnId: 1, key: "p_text_5" })}
                  line="down"
                  color="#E27007"
               />
               <ListItemLine
                  num="6"
                  text={renderData({ sectionId: 1, columnId: 1, key: "p_text_6" })}
                  line="up"
                  color="#650A9D"
               />
               <ListItemLine
                  num="7"
                  text={renderData({ sectionId: 1, columnId: 1, key: "p_text_7" })}
                  line="down"
                  color="#2E3192"
               />
               <ListItemLine
                  num="8"
                  text={renderData({ sectionId: 1, columnId: 1, key: "p_text_8" })}
               />
            </section>
         </TitledSection>

         <TitledSection
            title={
               <p style={{ color: "#00ADEF", marginBottom: 0 }}>
                  {renderData({ sectionId: 2, columnId: 0, key: "title" })}
               </p>
            }
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  {renderData({ sectionId: 2, columnId: 0, key: "subtitle" })}
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <section className="row mt-5">
               <div className="col-lg-6 d-flex px-2">
                  <CriteriaItem
                     title={renderData({ sectionId: 2, columnId: 1, key: "p_title_1" })}
                     desc={renderData({ sectionId: 2, columnId: 1, key: "p_text_1" })}
                  />
               </div>

               <div className="col-lg-6 d-flex px-2">
                  <CriteriaItem
                     title={renderData({ sectionId: 2, columnId: 1, key: "p_title_2" })}
                     desc={renderData({ sectionId: 2, columnId: 1, key: "p_text_2" })}
                  />
               </div>

               <div className="col-lg-4 d-flex px-2">
                  <CriteriaItem
                     title={renderData({ sectionId: 2, columnId: 1, key: "p_title_3" })}
                     desc={renderData({ sectionId: 2, columnId: 1, key: "p_text_3" })}
                  />
               </div>

               <div className="col-lg-4 d-flex px-2">
                  <CriteriaItem
                     title={renderData({ sectionId: 2, columnId: 1, key: "p_title_4" })}
                     desc={renderData({ sectionId: 2, columnId: 1, key: "p_text_4" })}
                  />
               </div>

               <div className="col-lg-4 d-flex px-2">
                  <CriteriaItem
                     title={renderData({ sectionId: 2, columnId: 1, key: "p_title_5" })}
                     desc={renderData({ sectionId: 2, columnId: 1, key: "p_text_5" })}
                  />
               </div>
            </section>
         </TitledSection>

         <TitledSection
            title={
               <p style={{ color: "#2E3192", marginBottom: 0 }}>
                  {renderData({ sectionId: 3, columnId: 0, key: "title" })}
               </p>
            }
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  {renderData({ sectionId: 3, columnId: 0, key: "subtitle" })}
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <section className="row mt-5">
               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={globalDiscovery}
                        title={renderData({ sectionId: 3, columnId: 1, key: "p_text_1" })}
                        bg="#0D0E32"
                        desc=""
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={accessGlobal}
                        title={renderData({ sectionId: 3, columnId: 1, key: "p_text_2" })}
                        bg="#0D0E32"
                        desc=""
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={increaseSale}
                        title={renderData({ sectionId: 3, columnId: 1, key: "p_text_3" })}
                        bg="#0D0E32"
                        desc=""
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={accessFunding}
                        title={renderData({ sectionId: 3, columnId: 1, key: "p_text_4" })}
                        bg="#0D0E32"
                        desc=""
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={accessOpp}
                        title={renderData({ sectionId: 3, columnId: 1, key: "p_text_5" })}
                        bg="#0D0E32"
                        desc=""
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={accessGlobal}
                        title={renderData({ sectionId: 3, columnId: 1, key: "p_text_6" })}
                        bg="#0D0E32"
                        desc=""
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={globalDiscovery}
                        title={renderData({ sectionId: 3, columnId: 1, key: "p_text_7" })}
                        bg="#0D0E32"
                        desc=""
                     />
                  </article>
               </div>
            </section>
         </TitledSection>

         {/* <section>
            <FeatureInvestor />
         </section> */}

         <TitledSection
            title={renderData({ sectionId: 4, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 4, columnId: 0, key: "subtitle" })}
            bg="#F9F9FB"
         >
            <section className="d-flex align-items-center w-100 flex-wrap mt-5">
               {sectorItem.map((s, i) => {
                  return (
                     <div key={i} className="w-100 sector-item-wrap mb-5">
                        <SectorItem text={s.text} color={s.color} />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         {/* <section className="d-flex align-items-center justify-content-between flex-wrap featured-investor">
            <div className="partner-startup-text">
               <p className="mb-0 partner-startup-title">Partners</p>
               <h2 className="mb-0">A global community built on collaboration</h2>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-wrap partner-startup-icons">
               <img src={apple} alt="apple" />
               <img src={jeep} alt="jeep" />
               <img src={google} alt="google+" />
               <img src={nike} alt="nike" />
               <img src={tripAdvisor} alt="ovo" />
               <img src={github} alt="github" />
               <img src={tripAdvisor} alt="ovo" />
               <img src={github} alt="github" />
            </div>
         </section> */}

         <section className="get-started-space">
            <GetStartedCard text="Join our startup program today!" />
         </section>
      </div>
   );
});

const CriteriaItem = ({ title = "", desc = "" }) => {
   return (
      <div className="startup-criteria-card d-flex">
         <RingBullet size={44} color="#44AB96" border="#FFFFFF" />
         <section>
            <h5>{title}</h5>
            <p>{desc}</p>
         </section>
      </div>
   );
};

const SectorItem = ({ color = "#44AB96", text = "" }) => {
   return (
      <div className="sector-item d-flex align-items-center">
         <RingBullet size={44} color={color} />
         <p className="mb-0">{text}</p>
      </div>
   );
};
