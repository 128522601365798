import React from "react";
import "./banner.css";
import { Button } from "../index";
import { useLocation } from "react-router-dom";

export const Banner = ({
   mainText = "",
   background = "",
   subText = "",
   buttonTitle = "Get Started",
   btnAction = () => {},
   height = 600,
   children,
   ...rest
}) => {
   const { pathname } = useLocation();
   let redirectPath = pathname.split("/")[1];

   return (
      <div
         className={`row mx-0 ${rest?.className ?? ""} banner`}
         style={{
            background: `${
               background.length === 0
                  ? "#000000"
                  : `linear-gradient(
      to right bottom,
      rgba(0,0,0, 0.1),
      rgba(0,0,0, 0.1)
    ),url(${background})`
            }`,
            minHeight: height,
            backgroundColor: "#000000"
         }}
      >
         <section className="col-xl-7 col-lg-10 d-flex align-items-center">
            <div>
               {mainText.length !== 0 && <div className="banner-main">{mainText}</div>}
               {subText.length > 0 && <p className="banner-sub">{subText}</p>}
               {buttonTitle.length > 0 && (
                  <Button
                     label={buttonTitle}
                     onClick={() =>
                        window.open(
                           `https://app.knight.ventures/sign-up-${
                              redirectPath === "accelerator" ? "startup" : redirectPath
                           }`,
                           "_blank"
                        )
                     }
                  />
               )}
               {/* {buttonTitle.length > 0 && <Button label={buttonTitle} />} */}
            </div>
         </section>

         {React.Children.count(children) > 0 && (
            <section className="col-12 banner-child">{children}</section>
         )}
      </div>
   );
};
