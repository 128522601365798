import React, { useEffect, useState } from "react";
import {
   Banner,
   TitledSection,
   WithLayout,
   // PersonCard,
   InfoCard,
   GetStartedCard
} from "../../components";
import banner from "../../assets/images/mentorBanner.png";
// import sampleFin from "../../assets/images/sampleFinMentors.png";
import diverseNetwork from "../../assets/images/diverseNetwork.svg";
import pitching from "../../assets/images/pitchingSes.svg";
import investOpp from "../../assets/images/investorConnect.svg";
import masterclass from "../../assets/images/investorMasterclass.svg";
import mentorOpp from "../../assets/images/mentorOpp.svg";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { request } from "../../util";

export const Mentors = WithLayout(() => {
   const [jsonData, setJsonData] = useState([]);
   const renderData = ({ sectionId, columnId, key }) => {
      if (jsonData.length > 0) {
         if (
            jsonData[sectionId] &&
            jsonData[sectionId][columnId] &&
            jsonData[sectionId][columnId]?.data
         ) {
            const data = jsonData[sectionId][columnId]?.data[key];

            return data;
         }

         return "";
      }
   };

   useEffect(() => {
      const getData = async () => {
         try {
            const data = await request.post("/api/page/details", {
               slug: "mentorszax",
               limit: 30,
               page: 1,
            });

            setJsonData(JSON.parse(data?.data?.data?.data));
         } catch (error) {
            toast.error("Could not fetch data");

            console.log(error);
         }
      };
      getData();
   }, []);
   return (
      <div>
         <Toaster />
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     {renderData({ sectionId: 0, columnId: 0, key: "title_1" })} <br />
                     <span>{renderData({ sectionId: 0, columnId: 0, key: "title_2" })}</span>{" "}
                     {renderData({ sectionId: 0, columnId: 0, key: "title_3" })}
                  </h1>
               }
               subText={renderData({ sectionId: 0, columnId: 0, key: "text" })}
            />
         </section>

         {/* <TitledSection
            title="Our leading mentors"
            subtitle="Meet some of our mentors"
            titlePosition="center"
         >
            <section className="row mt-5">
               {Array.from("fourFour").map((x, i) => {
                  return (
                     <div className="col-lg-3 mb-4" key={`financials-${i}`}>
                        <PersonCard name="Andy Ford" pic={sampleFin} role="Mentor" />
                     </div>
                  );
               })}
            </section>
         </TitledSection> */}

         <TitledSection
            title={
               <p style={{ color: "#00ADEF", marginBottom: 0 }}>
                  {renderData({ sectionId: 1, columnId: 0, key: "title" })}
               </p>
            }
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  {renderData({ sectionId: 1, columnId: 0, key: "subtitle" })}
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <section className="row mt-5">
               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={diverseNetwork}
                        title={renderData({ sectionId: 1, columnId: 1, key: "p_title_1" })}
                        bg="#0D0E32"
                        desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_1" })}
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={pitching}
                        title={renderData({ sectionId: 1, columnId: 1, key: "p_title_2" })}
                        desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_2" })}
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={masterclass}
                        title={renderData({ sectionId: 1, columnId: 1, key: "p_title_3" })}
                        bg="#0D0E32"
                        desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_3" })}
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={investOpp}
                        title={renderData({ sectionId: 1, columnId: 1, key: "p_title_4" })}
                        bg="#0D0E32"
                        desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_4" })}
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={mentorOpp}
                        title={renderData({ sectionId: 1, columnId: 1, key: "p_title_5" })}
                        bg="#0D0E32"
                        desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_5" })}
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={pitching}
                        title={renderData({ sectionId: 1, columnId: 1, key: "p_title_6" })}
                        bg="#0D0E32"
                        desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_6" })}
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2 depressed-list">
                  <article className="mb-3">
                     <InfoCard
                        icon={diverseNetwork}
                        title={renderData({ sectionId: 1, columnId: 1, key: "p_title_7" })}
                        bg="#0D0E32"
                        desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_7" })}
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={masterclass}
                        title={renderData({ sectionId: 1, columnId: 1, key: "p_title_8" })}
                        bg="#0D0E32"
                        desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_8" })}
                     />
                  </article>
               </div>
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our mentor program today!" />
         </section>
      </div>
   );
});
