import React, { useEffect } from "react";
import { useLocation } from "react-router";
import {
   WithLayout,
   Banner,
   TitledSection,
   PersonCard,
   GetStartedCard,
   PersonCardWithOutTwitter
} from "../../components";
import banner from "../../assets/images/aboutBanner.png";
import sample1 from "../../assets/images/jamil.jpg";
import founder from "../../assets/images/founderKV.png";
import sampleMember1 from "../../assets/images/olutosin.png";
import sampleMember2 from "../../assets/images/tosin.jpg";
import sampleMember3 from "../../assets/images/esther.jpg";
import bolaji from "../../assets/images/Bolaji.png";

export const About = WithLayout(() => {
   const advisorData = [
      {
         image: sample1,
         name: "Jamil Akhtar",
         role: "Advisor",
         linkedIn: "https://www.linkedin.com/in/jamilakhtar7/",
         twitter: "https://twitter.com/akhtarjamil07"
      },
      {
         image: bolaji,
         name: "Bolaji Sofoluwe",
         role: "Advisor",
         linkedIn: "https://www.linkedin.com/in/bolaji-sofoluwe/",
         twitter: "https://twitter.com/Blajay"
      },
      {
         image: founder,
         name: "Mope Abudu",
         role: "Founder | CEO",
         linkedIn: "https://www.linkedin.com/in/mope-abudu-72864717",
         twitter: "https://twitter.com/mopsabudu"
      }
      // { image: sample1 }
   ];
   const memberData = [
      { 
         name: "Mope Abudu", 
         image: founder, 
         role: "Founder | CEO",
         linkedIn: "https://www.linkedin.com/in/mope-abudu-72864717" 
      },
      {
         name: "Olutosin Aarinola",
         image: sampleMember1,
         role: "Program Director & Legal Administration",
         linkedIn: "https://www.linkedin.com/in/olutosin-aarinola-acis-b-l-ll-b-46180932/"
      },
      {
         name: "Tosin Solabi",
         image: sampleMember2,
         role: "Partner Engagement / Research",
         linkedIn: "https://www.linkedin.com/in/oluwatosin-solabi-293668138/"
      },
      {
         name: "Esther Ajudeonu",
         image: sampleMember3,
         role: "Curriculum / Onboarding Co-ordinator",
         linkedIn: "https://www.linkedin.com/in/esther-ajudeonu-67342a193/"
      }
   ];

   const { hash } = useLocation();

   useEffect(() => {
      const scrollToHash = (currentHash) => {
         var element = document.querySelector(`${currentHash}`);

         element.scrollIntoView();
      };

      if (hash) {
         scrollToHash(hash);
      }
   });

   return (
      <div>
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     Building Africa’s <span>leading innovation</span> and{" "}
                     <span>Accelerator platform</span>
                  </h1>
               }
               subText="Connecting investors with the most promising startups to create change around Africa."
            />
         </section>

         <TitledSection
            title="Advisors"
            subtitle="Our board of advisors are made up of the best in the industry"
            titlePosition="center"
            className="mt-5"
         >
            <section className="row mt-5">
               {advisorData.map((x, i) => {
                  return (
                     <div className="col-lg-4 mb-4" key={`advisors-${i}`}>
                        <PersonCard
                           name={x.name}
                           pic={x.image}
                           role={x.role}
                           linkedIn={x.linkedIn}
                           twitter={x?.twitter ?? ""}
                           contentPosition="left"
                        />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         <TitledSection
            title="Meet the team"
            subtitle="We work the best team to ensure the growth of your business"
            titlePosition="center"
            id="team"
         >
            <section className="row mt-5">
               {memberData.map((x, i) => {
                  return (
                     <div className="col-lg-3 mb-4" key={`members-${i}`}>
                        <PersonCardWithOutTwitter
                           name={x.name}
                           pic={x.image}
                           role={x?.role ?? ""}
                           contentPosition="left"
                           linkedIn={x.linkedIn ?? ""}
                        />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our program today!" />
         </section>
      </div>
   );
});
