import React from "react";
import "./partnerSlide.css";
import Slider from "react-slick";
import zendesk from "../../assets/images/zendesk.svg";
import icit from "../../assets/images/icit.png";
import yebox from "../../assets/images/yebox.svg";
import kandb from "../../assets/images/KandBHR.jpeg";
import aws from "../../assets/images/aws-slider.svg";
import oracle from "../../assets/images/oracle-slider.svg";
import clickup from "../../assets/images/clickup.svg";
import twilio from "../../assets/images/twilio.png";
import salesforce from "../../assets/images/salesforce.png";
import docsend from "../../assets/images/docsend-slider.svg";

export const PartnerSlide = () => {
   var settings = {
      dots: false,
      infinite: true,
      speed: 8000,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: "linear",
      nextArrow: (
         <div className="slide-arrow">
            <i className="bi bi-chevron-right"></i>
         </div>
      ),
      prevArrow: (
         <div className="slide-arrow">
            <i className="bi bi-chevron-left"></i>
         </div>
      ),
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };
   return (
      <div className="partner-slide">
         <Slider {...settings}>
            <div>
               <img src={zendesk} alt="Zendesk" />
            </div>

            <div>
               <img src={icit} alt="ICIT" />
            </div>

            <div>
               <img src={yebox} alt="Yebox Technologies" />
            </div>

            <div>
               <img src={kandb} alt="Knight and Bishop HRM" />
            </div>

            <div>
               <img src={aws} alt="aws Activate" />
            </div>

            {/* <div>
               <img src={oracle} alt="Oracle Startup" />
            </div> */}

            <div>
               <img src={clickup} alt="Clickup" />
            </div>

            <div>
               <img src={twilio} alt="Twilio" />
            </div>

            <div>
               <img src={salesforce} alt="Salesforce" />
            </div>

            <div>
               <img width={"80px"} height={"80px"} src={docsend} alt="Docsend" />
            </div>
         </Slider>
      </div>
   );
};
