import React, { useEffect, useState, useRef } from "react";
import banner from "../../assets/images/acceleratorBanner.png";
import {
   WithLayout,
   Banner,
   TitledSection,
   ProgramCard,
   RingBullet,
   Tab,
   Accordion,
   ListItemLine,
   GetStartedCard
} from "../../components";
import program1 from "../../assets/images/programImg1.png";
import program2 from "../../assets/images/programImg2.png";
import program3 from "../../assets/images/programImg3.png";
import accessFund from "../../assets/images/accessFund.svg";
import accessMentor from "../../assets/images/accessMentor.svg";
import investorConnect from "../../assets/images/investorConnect.svg";
import corporateConnect from "../../assets/images/corporateConnect.svg";
import support from "../../assets/images/supportWhy.svg";
import { data } from "../../data";
import "./accelerator.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { request } from "../../util";

export const Accelerator = WithLayout(() => {
   const [jsonData, setJsonData] = useState([]);
   const [more, setMore] = useState(false);
   const [programCardData, setProgramCardData] = useState({
      footer: "",
      fullDesc: "",
      active: false
   });
   const renderData = ({ sectionId, columnId, key }) => {
      if (jsonData.length > 0) {
         if (
            jsonData[sectionId] &&
            jsonData[sectionId][columnId] &&
            jsonData[sectionId][columnId]?.data
         ) {
            const data = jsonData[sectionId][columnId]?.data[key];

            return data;
         }

         return "";
      }
   };

   const ref = useRef(null);
   useEffect(() => {
      const handleClickOutside = (event) => {
         if (ref.current && !ref.current.contains(event.target)) {
            setMore && setMore(false);
            setProgramCardData({ ...programCardData, active: false });
         }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
         document.removeEventListener("click", handleClickOutside, true);
      };
   }, [setMore]);

   const curriculum = [
      {
         title: renderData({ sectionId: 2, columnId: 2, key: "p_title_1" }),
         desc: renderData({ sectionId: 2, columnId: 2, key: "p_text_1" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 2, key: "p_title_2" }),
         desc: renderData({ sectionId: 2, columnId: 2, key: "p_text_2" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 2, key: "p_title_3" }),
         desc: renderData({ sectionId: 2, columnId: 2, key: "p_text_3" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 2, key: "p_title_4" }),
         desc: renderData({ sectionId: 2, columnId: 2, key: "p_text_4" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 2, key: "p_title_5" }),
         desc: renderData({ sectionId: 2, columnId: 2, key: "p_text_5" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 2, key: "p_title_6" }),
         desc: renderData({ sectionId: 2, columnId: 2, key: "p_text_6" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 2, key: "p_title_7" }),
         desc: renderData({ sectionId: 2, columnId: 2, key: "p_text_7" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 3, key: "p_title_1" }),
         desc: renderData({ sectionId: 2, columnId: 3, key: "p_text_1" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 3, key: "p_title_2" }),
         desc: renderData({ sectionId: 2, columnId: 3, key: "p_text_2" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 3, key: "p_title_3" }),
         desc: renderData({ sectionId: 2, columnId: 3, key: "p_text_3" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 3, key: "p_title_4" }),
         desc: renderData({ sectionId: 2, columnId: 3, key: "p_text_4" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 3, key: "p_title_5" }),
         desc: renderData({ sectionId: 2, columnId: 3, key: "p_text_5" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 3, key: "p_title_6" }),
         desc: renderData({ sectionId: 2, columnId: 3, key: "p_text_6" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 3, key: "p_title_7" }),
         desc: renderData({ sectionId: 2, columnId: 3, key: "p_text_7" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 3, key: "p_title_8" }),
         desc: renderData({ sectionId: 2, columnId: 3, key: "p_text_8" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_1" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_1" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_2" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_2" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_3" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_3" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_4" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_4" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_5" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_5" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_6" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_6" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_7" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_7" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_8" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_8" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_9" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_9" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_10" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_10" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_11" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_11" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_12" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_12" })
      },
      {
         title: renderData({ sectionId: 2, columnId: 4, key: "p_title_13" }),
         desc: renderData({ sectionId: 2, columnId: 4, key: "p_text_13" })
      }
   ];
   const selectionData = [
      {
         text: renderData({ sectionId: 5, columnId: 1, key: "p_text_1" }),
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: renderData({ sectionId: 5, columnId: 1, key: "p_text_2" }),
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: renderData({ sectionId: 5, columnId: 1, key: "p_text_3" }),
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: renderData({ sectionId: 5, columnId: 1, key: "p_text_4" }),
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: renderData({ sectionId: 5, columnId: 1, key: "p_text_5" }),
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: renderData({ sectionId: 5, columnId: 2, key: "p_text_1" }),
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: renderData({ sectionId: 5, columnId: 2, key: "p_text_2" }),
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: renderData({ sectionId: 5, columnId: 2, key: "p_text_3" }),
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: renderData({ sectionId: 5, columnId: 2, key: "p_text_4" }),
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: renderData({ sectionId: 5, columnId: 2, key: "p_text_5" }),
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: renderData({ sectionId: 5, columnId: 3, key: "p_text_1" }),
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: renderData({ sectionId: 5, columnId: 3, key: "p_text_2" }),
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: renderData({ sectionId: 5, columnId: 3, key: "p_text_3" }),
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: renderData({ sectionId: 5, columnId: 3, key: "p_text_4" }),
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: renderData({ sectionId: 5, columnId: 3, key: "p_text_5" }),
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: renderData({ sectionId: 5, columnId: 3, key: "p_text_6" }),
         color: "#00ADEF",
         border: "#095876"
      }
   ];

   useEffect(() => {
      const getData = async () => {
         try {
            const data = await request.post("/api/page/details", {
               slug: "acceleratorse7",
               limit: 30,
               page: 1,
            });
            setJsonData(JSON.parse(data?.data?.data?.data));
         } catch (error) {
            toast.error("Could not fetch data");
            console.log(error);
         }
      };
      getData();
   }, []);

   useEffect(() => {
      if (programCardData.active) {
         setMore(true);
      }
   }, [programCardData]);

   const [criteriaActive, setCriteriaActive] = useState(0);
   const [curriculumActive, setCurriculumActive] = useState(0);
   const [start, setStart] = useState(0);
   const [stop, setStop] = useState(7);

   const [startCriteria, setStartCriteria] = useState(0);
   const [stopCriteria, setStopCriteria] = useState(5);

   useEffect(() => {
      if (curriculumActive === 0) {
         setStart(0);
         setStop(7);
      } else if (curriculumActive === 1) {
         setStart(7);
         setStop(15);
      } else if (curriculumActive === 2) {
         setStart(15);
         setStop(28);
      }
   }, [curriculumActive]);

   useEffect(() => {
      if (criteriaActive === 0) {
         setStartCriteria(0);
         setStopCriteria(5);
      } else if (criteriaActive === 1) {
         setStartCriteria(5);
         setStopCriteria(10);
      } else if (criteriaActive === 2) {
         setStartCriteria(10);
         setStopCriteria(16);
      }
   }, [criteriaActive]);

   const criteriaTab = [
      {
         name: "Incubation Program",
         action: () => {
            setCriteriaActive(0);
         }
      },
      {
         name: "Seed Acceleration Bootcamp",
         action: () => {
            setCriteriaActive(1);
         }
      },
      {
         name: "Series A, Seed, Pre-Series A",
         action: () => {
            setCriteriaActive(2);
         }
      }
   ];

   const curriculumTab = [
      {
         name: renderData({ sectionId: 2, columnId: 1, key: "p_title_1" }),
         action: () => {
            setCurriculumActive(0);
         }
      },
      {
         name: renderData({ sectionId: 2, columnId: 1, key: "p_title_2" }),
         action: () => {
            setCurriculumActive(1);
         }
      },
      {
         name: renderData({ sectionId: 2, columnId: 1, key: "p_title_3" }),
         action: () => {
            setCurriculumActive(2);
         }
      }
   ];

   return (
      <div>
         <Toaster />
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     {renderData({ sectionId: 0, columnId: 0, key: "title_1" })} <br />{" "}
                     <span>{renderData({ sectionId: 0, columnId: 0, key: "title_2" })}</span>{" "}
                     {renderData({ sectionId: 0, columnId: 0, key: "title_3" })}
                  </h1>
               }
               subText={renderData({ sectionId: 0, columnId: 0, key: "p_text" })}
            />
         </section>

         <TitledSection
            title={renderData({ sectionId: 1, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 1, columnId: 0, key: "subtitle" })}
         >
            <div className="row mt-5">
               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     image={program1}
                     more={more}
                     setMore={setMore}
                     setProgramCardData={setProgramCardData}
                     marginB="more-float-b"
                     footer={renderData({ sectionId: 1, columnId: 1, key: "p_title" })}
                     desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_1" })}
                     fullDesc={renderData({ sectionId: 1, columnId: 1, key: "p_text_2" })}
                  />
               </section>

               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     image={program2}
                     more={more}
                     setMore={setMore}
                     setProgramCardData={setProgramCardData}
                     footer={renderData({ sectionId: 1, columnId: 2, key: "p_title" })}
                     desc={renderData({ sectionId: 1, columnId: 2, key: "p_text_1" })}
                     fullDesc={renderData({ sectionId: 1, columnId: 2, key: "p_text_2" })}
                  />
               </section>

               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     image={program3}
                     more={more}
                     setMore={setMore}
                     setProgramCardData={setProgramCardData}
                     className="investor-connect-card"
                     footer={renderData({ sectionId: 1, columnId: 3, key: "p_title" })}
                     desc={renderData({ sectionId: 1, columnId: 3, key: "p_text_1" })}
                     fullDesc={renderData({ sectionId: 1, columnId: 3, key: "p_text_2" })}
                  />
               </section>
            </div>
            {more && (
               <section ref={ref} className={`more-float`}>
                  <p className="title">{programCardData.footer}</p>
                  <p className="desc">{programCardData.fullDesc}</p>
               </section>
            )}
         </TitledSection>

         <TitledSection
            title="Selection criteria"
            subtitle="Our selection criteria is pretty Simple"
            titlePosition="center"
         >
            <section className="d-flex justify-content-center mt-5 mb-5">
               <Tab tabItems={criteriaTab} activeTab={criteriaActive} />
            </section>

            <section className="row d-flex pt-3">
               {selectionData.slice(startCriteria, stopCriteria).map((s, i) => {
                  return (
                     <div key={i} className="col-lg-4 mb-3 px-2 d-flex w-100">
                        <Category color={s.color} text={s.text} border={s.border} />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         <TitledSection
            title={
               <p style={{ color: "#00ADEF", marginBottom: 0 }}>
                  {renderData({ sectionId: 2, columnId: 0, key: "title" })}
               </p>
            }
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  {renderData({ sectionId: 2, columnId: 0, key: "subtitle" })}
               </p>
            }
            bg="#02031B"
         >
            <section className="mb-5 mt-5 pt-3">
               <Tab tabItems={curriculumTab} activeTab={curriculumActive} tabType="dash" />
            </section>

            <section className="pt-3">
               <Accordion items={curriculum.slice(start, stop)} />
            </section>
         </TitledSection>

         <TitledSection
            title={renderData({ sectionId: 3, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 3, columnId: 0, key: "subtitle" })}
         >
            <section className="d-flex align-items-center mt-5 flex-wrap">
               <ListItemLine
                  num="1"
                  text={renderData({ sectionId: 3, columnId: 1, key: "p_text_1" })}
                  line="up"
               />
               <ListItemLine
                  num="2"
                  text={renderData({ sectionId: 3, columnId: 1, key: "p_text_2" })}
                  line="down"
                  color="#E27007"
               />
               <ListItemLine
                  num="3"
                  text={renderData({ sectionId: 3, columnId: 1, key: "p_text_3" })}
                  line="up"
                  color="#650A9D"
               />
               <ListItemLine
                  num="4"
                  text={renderData({ sectionId: 3, columnId: 1, key: "p_text_4" })}
                  color="#2E3192"
               />
            </section>

            <section className="d-flex align-items-center w-100 flex-wrap">
               <ListItemLine
                  num="5"
                  text={renderData({ sectionId: 3, columnId: 1, key: "p_text_5" })}
                  line="down"
                  color="#E27007"
               />
               <ListItemLine
                  num="6"
                  text={renderData({ sectionId: 3, columnId: 1, key: "p_text_6" })}
                  line="up"
                  color="#650A9D"
               />
               <ListItemLine
                  num="7"
                  text={renderData({ sectionId: 3, columnId: 1, key: "p_text_7" })}
                  line="down"
                  color="#2E3192"
               />
               <ListItemLine
                  num="8"
                  text={renderData({ sectionId: 3, columnId: 1, key: "p_text_8" })}
               />
            </section>
         </TitledSection>

         <TitledSection
            title={renderData({ sectionId: 4, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 4, columnId: 0, key: "subtitle" })}
            titlePosition="center"
            className="px-4"
         >
            <section className="row mt-5">
               <div className="col-lg-3">
                  <article className="mb-5">
                     <WhyItem
                        icon={accessFund}
                        title={renderData({ sectionId: 4, columnId: 1, key: "p_title_1" })}
                        color="#2E319210"
                        desc={renderData({ sectionId: 4, columnId: 1, key: "p_text_1" })}
                     />
                  </article>

                  <article className="mb-5">
                     <WhyItem
                        icon={investorConnect}
                        title={renderData({ sectionId: 4, columnId: 1, key: "p_title_2" })}
                        color="#17B3EE10"
                        desc={renderData({ sectionId: 4, columnId: 1, key: "p_text_2" })}
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list">
                  <article className="mb-5">
                     <WhyItem
                        icon={accessMentor}
                        title={renderData({ sectionId: 4, columnId: 1, key: "p_title_3" })}
                        color="#E2700710"
                        desc={renderData({ sectionId: 4, columnId: 1, key: "p_text_3" })}
                     />
                  </article>

                  <article className="mb-5">
                     <WhyItem
                        icon={corporateConnect}
                        title={renderData({ sectionId: 4, columnId: 1, key: "p_title_4" })}
                        color="#1880AC10"
                        desc={renderData({ sectionId: 4, columnId: 1, key: "p_text_4" })}
                     />
                  </article>
               </div>

               <div className="col-lg-3">
                  <article className="mb-5">
                     <WhyItem
                        icon={investorConnect}
                        title={renderData({ sectionId: 4, columnId: 1, key: "p_title_5" })}
                        color="#17B3EE10"
                        desc={renderData({ sectionId: 4, columnId: 1, key: "p_text_5" })}
                     />
                  </article>

                  <article className="mb-5">
                     <WhyItem
                        icon={corporateConnect}
                        title={renderData({ sectionId: 4, columnId: 1, key: "p_title_6" })}
                        color="#1880AC10"
                        desc={renderData({ sectionId: 4, columnId: 1, key: "p_text_6" })}
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list">
                  <article className="mb-5">
                     <WhyItem
                        icon={support}
                        title={renderData({ sectionId: 4, columnId: 1, key: "p_title_7" })}
                        color="#650A9D10"
                        desc={renderData({ sectionId: 4, columnId: 1, key: "p_text_7" })}
                     />
                  </article>

                  <article className="mb-5">
                     <WhyItem
                        icon={accessMentor}
                        title={renderData({ sectionId: 4, columnId: 1, key: "p_title_8" })}
                        color="#E2700710"
                        desc={renderData({ sectionId: 4, columnId: 1, key: "p_text_8" })}
                     />
                  </article>
               </div>
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our accelerator program today!" />
         </section>
      </div>
   );
});

const Category = ({ text = "", color = "#00ADEF", border = "#095876" }) => {
   return (
      <div className="d-flex align-items-center cat-card w-100">
         <RingBullet size={44} color={color} border={border} />
         <p>{text}</p>
      </div>
   );
};

const WhyItem = ({ title = "", desc = "", color = "", icon = "" }) => {
   return (
      <div className="d-flex" style={{ columnGap: "1rem" }}>
         <span className="why-icon" style={{ backgroundColor: color }}>
            <img src={icon} alt="icon" />
         </span>
         <section className="why-detail">
            <h5>{title}</h5>
            <p>{desc}</p>
         </section>
      </div>
   );
};
