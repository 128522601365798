import React, { useEffect, useRef, useState } from "react";
import {
   BlogCard,
   Button,
   // FeatureInvestor,
   InfoCard,
   PartnerSlide,
   ProgramCard,
   RingBullet,
   // Testimony,
   TextField,
   TitledSection,
   WithLayout
} from "../../components";
import program1 from "../../assets/images/programImg1.png";
import program2 from "../../assets/images/programImg2.png";
import program3 from "../../assets/images/programImg3.png";
import deco from "../../assets/images/worksDeco.svg";
import incorporated from "../../assets/images/startupIncoporated.svg";
import minimum from "../../assets/images/minFounder.svg";
import active from "../../assets/images/activeCustomer.svg";
import prototype from "../../assets/images/workPrototype.svg";
import incubated from "../../assets/images/incubateIdea.svg";
import globalLocal from "../../assets/images/globalLocalMentor.svg";
import networkPartner from "../../assets/images/networkPartners.svg";
import courseAccess from "../../assets/images/courseAccess.svg";
import founderCom from "../../assets/images/founderCommunity.svg";
import accessInvestor from "../../assets/images/accessInvestors.svg";
import remoteProgram from "../../assets/images/remoteProgramm.svg";
import legal from "../../assets/images/legalAssistance.svg";
import brand from "../../assets/images/Brand&PR.svg";
import ideasToReality from "../../assets/images/ideasToReality.png";
import knightBishop from "../../assets/images/knightBishop.jpg";
// import productRoadMap from "../../assets/images/productRoadmap.png";
import landingVideo from "../../assets/images/bgVideo.mp4";
import toast, { Toaster } from "react-hot-toast";
import "./home.css";
import { getPageDetails, getPublishedBlog } from "../../services";
import axios from "axios";
import { request } from "../../util";
import { useNavigate } from "react-router-dom";

export const Home = WithLayout(() => {
   const [jsonData, setJsonData] = useState([]);
   const [blog, setBlog] = useState();
   const [more, setMore] = useState(false);
   const [programCardData, setProgramCardData] = useState({
      footer: "",
      fullDesc: "",
      active: false
   });
   const navigate = useNavigate();

   const renderData = ({ sectionId, columnId, key }) => {
      if (jsonData.length > 0) {
         if (
            jsonData[sectionId] &&
            jsonData[sectionId][columnId] &&
            jsonData[sectionId][columnId]?.data
         ) {
            const data = jsonData[sectionId][columnId]?.data[key];

            return data;
         }

         return "";
      }
   };

   const [pageDetails, setPageDetails] = useState([]);

   const ref = useRef(null);
   useEffect(() => {
      const handleClickOutside = (event) => {
         if (ref.current && !ref.current.contains(event.target)) {
            setMore && setMore(false);
            setProgramCardData({ ...programCardData, active: false });
         }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
         document.removeEventListener("click", handleClickOutside, true);
      };
   }, [setMore]);

   useEffect(() => {
      const fetchPageDetails = async () => {
         const res = await getPageDetails({slug: "homeik7", page: 1, limit: 30});
         setPageDetails(res);
         console.log(res);
      };
      fetchPageDetails();
      // return () => setPageDetails();
   }, []);

   useEffect(() => {
      const fetchBlog = async () => {
         try {
            const res = await getPublishedBlog();
            setBlog(res);
            console.log(res);
         } catch (error) {
            toast.error("Could not fetch data");
         }
      };
      fetchBlog();
   }, []);

   const whyJoin = [
      {
         icon: globalLocal,
         title: renderData({ sectionId: 5, columnId: 1, key: "p_title" }),
         desc: renderData({ sectionId: 5, columnId: 1, key: "p_text" })
      },
      {
         icon: networkPartner,
         title: renderData({ sectionId: 5, columnId: 2, key: "p_title" }),
         desc: renderData({ sectionId: 5, columnId: 2, key: "p_text" })
      },
      {
         icon: courseAccess,
         title: renderData({ sectionId: 5, columnId: 3, key: "p_title" }),
         desc: renderData({ sectionId: 5, columnId: 3, key: "p_text" })
      },
      {
         icon: founderCom,
         title: renderData({ sectionId: 5, columnId: 4, key: "p_title" }),
         desc: renderData({ sectionId: 5, columnId: 4, key: "p_text" })
      },
      {
         icon: accessInvestor,
         title: renderData({ sectionId: 5, columnId: 5, key: "p_title" }),
         desc: renderData({ sectionId: 5, columnId: 5, key: "p_text" })
      },
      {
         icon: remoteProgram,
         title: renderData({ sectionId: 5, columnId: 6, key: "p_title" }),
         desc: renderData({ sectionId: 5, columnId: 6, key: "p_text" })
      },
      {
         icon: legal,
         title: renderData({ sectionId: 5, columnId: 7, key: "p_title" }),
         desc: renderData({ sectionId: 5, columnId: 7, key: "p_text" })
      },
      {
         icon: brand,
         title: renderData({ sectionId: 5, columnId: 8, key: "p_title" }),
         desc: renderData({ sectionId: 5, columnId: 8, key: "p_text" })
      }
   ];

   // const videoRef = useRef(null);

   // useEffect(() => {
   //    console.log("d");
   //    videoRef.current.play();
   // }, []);

   // useEffect(() => {
   //    const video = videoRef.current;
   //    video.onplay = () => {
   //       videoRef.current.play();
   //    };
   // }, []);
   const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
   ];
   const formatDate = (dateToFormat) => {
      return `${new Date(dateToFormat).getDate()} ${
         months[new Date(dateToFormat).getMonth()]
      }, ${new Date(dateToFormat).getFullYear()}`;
   };

   useEffect(() => {
      const getData = async () => {
         try {
            const data = await request.post("/api/page/details", {
               slug: "homeik7",
               page: 1, 
               limit: 100
            });
            setJsonData(JSON.parse(data?.data?.data?.data));
         } catch (error) {
            toast.error("Could not fetch data");
            console.log(error.response);
         }
      };
      getData();
   }, []);

   useEffect(() => {
      if (programCardData.active) {
         setMore(true);
      }
   }, [programCardData]);

   return (
      <div>
         <Toaster />

         <section className="position-relative">
            <video
               // preload="none"
               autoPlay
               loop
               muted
               id="myVideo"
               style={{
                  width: "100%",
                  minHeight: "600px",
                  maxHeight: "600px",
                  backgroundColor: "#000",
                  objectFit: "cover"
               }}
               // ref={videoRef}
            >
               <source
                  src="https://d1kvhdvvoghu6a.cloudfront.net/bgVideoggzt1i7ssmwpusqz1oxa.mp4"
                  type="video/mp4"
               />
            </video>

            <div
               className={`row mx-0 banner w-100`}
               style={{
                  Height: 600,
                  position: "absolute",
                  zIndex: 10,
                  top: 0,
                  backgroundColor: "#00000050"
               }}
            >
               <section className="col-xl-7 col-lg-10 d-flex align-items-center">
                  <div>
                     <div className="banner-main">
                        <h1>
                           {renderData({ sectionId: 0, columnId: 0, key: "intro_1" })}{" "}
                           <span>{renderData({ sectionId: 0, columnId: 0, key: "intro_2" })}</span>
                        </h1>
                     </div>
                     <p className="banner-sub">
                        {renderData({ sectionId: 0, columnId: 0, key: "intro_3" })}
                     </p>
                     <Button
                        label="Get Started"
                        onClick={() => {
                           window.location.href = "https://app.knight.ventures/sign-in";
                        }}
                     />
                     {/* <Button label="Get Started" /> */}
                     {/* <a href="https://knight.ventures/waitlist.html">Get Started</a> */}
                  </div>
               </section>
            </div>
         </section>

         <TitledSection
            title={renderData({ sectionId: 1, columnId: 0, key: "program_1" })}
            subtitle={renderData({ sectionId: 1, columnId: 0, key: "program_2" })}
         >
            <div className="row mt-5">
               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     more={more}
                     setMore={setMore}
                     setProgramCardData={setProgramCardData}
                     marginB="more-float-b"
                     image={program1}
                     footer={renderData({ sectionId: 1, columnId: 1, key: "p_text_title" })}
                     desc={renderData({ sectionId: 1, columnId: 1, key: "p_text_small" })}
                     fullDesc={renderData({ sectionId: 1, columnId: 1, key: "p_text_big" })}
                  />
               </section>

               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     more={more}
                     setMore={setMore}
                     setProgramCardData={setProgramCardData}
                     image={program2}
                     footer={renderData({ sectionId: 1, columnId: 2, key: "p_text_title" })}
                     desc={renderData({ sectionId: 1, columnId: 2, key: "p_text_small" })}
                     fullDesc={renderData({ sectionId: 1, columnId: 2, key: "p_text_big" })}
                  />
               </section>

               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     more={more}
                     setMore={setMore}
                     setProgramCardData={setProgramCardData}
                     className="investor-connect-card"
                     image={program3}
                     footer={renderData({ sectionId: 1, columnId: 3, key: "p_text_title" })}
                     desc={renderData({ sectionId: 1, columnId: 3, key: "p_text_small" })}
                     fullDesc={renderData({ sectionId: 1, columnId: 3, key: "p_text_big" })}
                  />
               </section>
            </div>
            {more && (
               <section ref={ref} className={`more-float`}>
                  <p className="title">{programCardData.footer}</p>
                  <p className="desc">{programCardData.fullDesc}</p>
               </section>
            )}
         </TitledSection>

         <TitledSection
            title={renderData({ sectionId: 2, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 2, columnId: 0, key: "subtitle" })}
            titlePosition="center"
            bg="#F9F9FB"
            className="px-3"
         >
            <section
               className="d-flex align-items-center justify-content-center flex-wrap"
               style={{ paddingTop: "4rem", columnGap: 50, rowGap: 18 }}
            >
               <section className="works-section">
                  <div className="d-flex align-items-center mb-5">
                     <RingBullet />
                     <div className="ml-4">
                        <p className="works-title">
                           {renderData({ sectionId: 2, columnId: 1, key: "p_title" })}
                        </p>
                        <p className="mb-0 works-desc">
                           {renderData({ sectionId: 2, columnId: 1, key: "p_text" })}
                        </p>
                     </div>
                  </div>

                  <div className="d-flex align-items-center mb-5">
                     <RingBullet color="#2E3192" border="#979797" />
                     <div className="ml-4">
                        <p className="works-title">
                           {renderData({ sectionId: 2, columnId: 2, key: "p_title" })}
                        </p>
                        <p className="mb-0 works-desc">
                           {renderData({ sectionId: 2, columnId: 2, key: "p_text" })}
                        </p>
                     </div>
                  </div>
               </section>
               <section style={{ flexBasis: "20%" }}>
                  <img src={deco} alt="deco" className="w-100" />
               </section>

               <section className="works-section">
                  <div className="d-flex align-items-center mb-5">
                     <RingBullet color="#2E3192" border="#979797" />
                     <div className="ml-4">
                        <p className="works-title">
                           {renderData({ sectionId: 2, columnId: 3, key: "p_title" })}
                        </p>
                        <p className="mb-0 works-desc">
                           {renderData({ sectionId: 2, columnId: 3, key: "p_text" })}
                        </p>
                     </div>
                  </div>
                  <div className="d-flex align-items-center mb-5">
                     <RingBullet />
                     <div className="ml-4">
                        <p className="works-title">
                           {renderData({ sectionId: 2, columnId: 4, key: "p_title" })}
                        </p>
                        <p className="mb-0 works-desc">
                           {renderData({ sectionId: 2, columnId: 4, key: "p_text" })}
                        </p>
                     </div>
                  </div>
               </section>
            </section>
         </TitledSection>

         <TitledSection
            title={renderData({ sectionId: 3, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 3, columnId: 0, key: "subtitle" })}
         >
            <section className="row mt-5">
               <div className="col-lg-4 d-flex align-items-center justify-content-center mb-5">
                  <InfoCard
                     title={renderData({ sectionId: 3, columnId: 1, key: "p_title" })}
                     desc={renderData({ sectionId: 3, columnId: 1, key: "p_text" })}
                     color="#2E3192"
                     icon={incorporated}
                  />
               </div>

               <div className="col-lg-4 d-flex flex-column align-items-center mb-5">
                  <section className="mb-5">
                     <InfoCard
                        title={renderData({ sectionId: 3, columnId: 2, key: "p_title" })}
                        desc={renderData({ sectionId: 3, columnId: 2, key: "p_text" })}
                        color="#E27007"
                        icon={prototype}
                     />
                  </section>

                  <section>
                     <InfoCard
                        title={renderData({ sectionId: 3, columnId: 3, key: "p_title" })}
                        desc={renderData({ sectionId: 3, columnId: 3, key: "p_text" })}
                        color="#44AB96"
                        icon={incubated}
                     />
                  </section>
               </div>

               <div className="col-lg-4 d-flex flex-column align-items-center last-set-criteria">
                  <section className="mb-5">
                     <InfoCard
                        title={renderData({ sectionId: 3, columnId: 4, key: "p_title" })}
                        desc={renderData({ sectionId: 3, columnId: 4, key: "p_text" })}
                        color="#650A9D"
                        icon={active}
                     />
                  </section>

                  <section>
                     <InfoCard
                        title={renderData({ sectionId: 3, columnId: 5, key: "p_title" })}
                        desc={renderData({ sectionId: 3, columnId: 5, key: "p_text" })}
                        color="#00ADEF"
                        icon={minimum}
                     />
                  </section>
               </div>
            </section>
         </TitledSection>

         <TitledSection
            className="partner-section"
            title={renderData({ sectionId: 4, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 4, columnId: 0, key: "subtitle" })}
         >
            <p>{renderData({ sectionId: 4, columnId: 0, key: "p_text" })}</p>
            <div className="partners-pad">
               <PartnerSlide />
            </div>
         </TitledSection>

         <TitledSection
            title={
               <p style={{ color: "#00ADEF", marginBottom: 0 }}>
                  {renderData({ sectionId: 5, columnId: 0, key: "title" })}
               </p>
            }
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  {renderData({ sectionId: 5, columnId: 0, key: "subtitle" })}
               </p>
            }
            bg="#02031B"
            titlePosition="right"
            className="section-padding-top"
         >
            <section className="row mt-5">
               {whyJoin.map((w, i) => {
                  return (
                     <div key={i} className="col-lg-4 mb-3 px-2 d-flex justify-content-center">
                        <InfoCard bg="#0D0E32" title={w.title} icon={w.icon} desc={w.desc} />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         {/* <TitledSection
            title={<p style={{ color: "#00ADEF", marginBottom: 0 }}>Testimonials</p>}
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  Watch some of our cohorts and learn from their stories
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <Testimony />
         </TitledSection> */}

         {/* <TitledSection
            subtitle={<p style={{ color: "#ffffff", marginBottom: 0 }}>Our Capabilities</p>}
            bg="#02031B"
         >
            <section className="earth-space">
               <div className="row pt-3" style={{ opacity: 1 }}>
                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">200+</p>
                     <p className="capacity-label">Startups</p>
                  </article>

                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">30%</p>
                     <p className="capacity-label">Women Founders</p>
                  </article>

                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">$113m</p>
                     <p className="capacity-label">Fund Raised</p>
                  </article>
               </div>

               <div className="row lower-list-capacity" style={{ opacity: 1 }}>
                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">9500+</p>
                     <p className="capacity-label">Investors</p>
                  </article>

                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">20+</p>
                     <p className="capacity-label">Partners</p>
                  </article>

                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">60+</p>
                     <p className="capacity-label">Mentors</p>
                  </article>
               </div>
            </section>
         </TitledSection> */}

         <TitledSection
            title={renderData({ sectionId: 6, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 6, columnId: 0, key: "subtitle" })}
         >
            <section className="position-blog-btn">
               <Button
                  label="Go to our blog"
                  onClick={() => {
                     window.location.href = "/blog";
                  }}
                  className="blog-sec-btn"
               />
            </section>
            {blog?.data?.blogs?.length <= 0 ? (
               <div className="d-flex align-items-center justify-content-center">
                  <TitledSection
                     title="Check back some other time"
                     subtitle="No Blogs Available"
                  ></TitledSection>
               </div>
            ) : (
               <section className="d-flex align-items-center home-blog-list">
                  {/* {blog?.data?.data.map((item, i) => {
                  return (
                     <div key={i} className="col-lg-4 mb-4">
                        <BlogCard
                           title={item?.title}
                           subtitle={"Knight Ventures"}
                           date={formatDate(item?.publish)}
                           // tags={["Accounting", "Startups"]}
                           bg={item?.cover}
                           id={item?.slug}
                        />
                     </div>
                  );
               })} */}

                  {blog?.data?.blogs[0] && (
                     <BlogCard
                        bg={blog?.data?.blogs[0].cover}
                        title={"Knight Ventures"}
                        subtitle={blog?.data?.blogs[0]?.title}
                        // day="22"
                        // month="Sep"
                        onClick={() => navigate(`/blog/${blog?.data?.blogs[0]?.slug}`)}
                     />
                  )}
                  {blog?.data?.blogs[1] && (
                     <BlogCard
                        bg={blog?.data?.blogs[1].cover}
                        title={"Knight Ventures"}
                        subtitle={blog?.data?.blogs[1]?.title}
                        // day="26"
                        // month="Aug"
                        // width={280}
                        onClick={() => navigate(`/blog/${blog?.data?.blogs[0]?.slug}`)}
                     />
                  )}
                  {/* <BlogCard
                  bg={productRoadMap}
                  title="Investors"
                  subtitle="Getting started as an investor"
                  day="13"
                  month="Jun"
                  width={280}
               /> */}
               </section>
            )}
         </TitledSection>

         {/* <FeatureInvestor /> */}

         <section className="d-flex align-items-center justify-content-between subscribe-news flex-wrap">
            <h2>Subscribe our news letter to stay updated</h2>
            <div className="d-flex align-items-center flex-wrap">
               <TextField placeholder="Your Name" />

               <TextField placeholder="Your Email" type="email" />

               <Button label="Subscribe" />
            </div>
         </section>
      </div>
   );
});
