import React from "react";
import {
   Banner,
   TitledSection,
   WithLayout,
   // PersonCard,
   InfoCard,
   GetStartedCard
} from "../components";
import banner from "../assets/images/mentorBanner.png";
// import sampleFin from "../../assets/images/sampleFinMentors.png";

export const Error = WithLayout(() => {
   return (
      <div>
         <section>
            <Banner
               background={banner}
               mainText="Oops!!! Page Not Found"
               // subText={renderData({ sectionId: 0, columnId: 0, key: "text" })}
            />
         </section>

         <section className="get-started-space">
            <GetStartedCard text="Join our program today!" />
         </section>
      </div>
   );
});
