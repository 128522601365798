import { request } from "../util";

export const getPage = async () => {
   try {
      const res = await request.get("/api/page");
      return res.data;
   } catch (error) {
      throw error;
   }
};

export const getPageDetails = async (data) => {
   try {
      const res = await request.post("/api/page/details", data);
      return res.data;
   } catch (error) {
      throw error;
   }
};