import React, { useEffect, useState } from "react";
import {
   Banner,
   TitledSection,
   WithLayout,
   PartnerCard,
   NoListCard,
   OneListCard,
   TwoListCard,
   GetStartedCard,
   PartnerHeader
} from "../../components";
import banner from "../../assets/images/partnerBanner.png";
import kandHR from "../../assets/images/KandBHR.jpeg";
import yebox from "../../assets/images/yebox.svg";
import zendesk from "../../assets/images/zendesk.svg";
import icit from "../../assets/images/icit.png";
import docsend from "../../assets/images/docsend.png";
import aws from "../../assets/images/aws-activate.svg";
import oracle from "../../assets/images/oracle.png";
import clickup from "../../assets/images/clickup.svg";
import firstbase from "../../assets/images/firstbase.png";
import twilio from "../../assets/images/twilio.png";
import salesforce from "../../assets/images/salesforce.png";
import toast, { Toaster } from "react-hot-toast";
import "./partners.css";
import axios from "axios";
import { request } from "../../util";

export const Partners = WithLayout(() => {
   const [jsonData, setJsonData] = useState([]);
   const renderData = ({ sectionId, columnId, key }) => {
      if (jsonData.length > 0) {
         if (
            jsonData[sectionId] &&
            jsonData[sectionId][columnId] &&
            jsonData[sectionId][columnId]?.data
         ) {
            const data = jsonData[sectionId][columnId]?.data[key];

            return data;
         }

         return "";
      }
   };

   useEffect(() => {
      const getData = async () => {
         try {
            const data = await request.post("/api/page/details", {
               slug: "partnersep5",
               limit: 30,
               page: 1,
            });

            setJsonData(JSON.parse(data?.data?.data?.data));
         } catch (error) {
            toast.error("Could not fetch data");

            console.log(error);
         }
      };
      getData();
   }, []);
   return (
      <div>
         <Toaster />
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     <span>{renderData({ sectionId: 0, columnId: 0, key: "title_1" })}</span>{" "}
                     {renderData({ sectionId: 0, columnId: 0, key: "title_2" })}
                  </h1>
               }
               subText={renderData({ sectionId: 0, columnId: 0, key: "text" })}
            />
         </section>

         <TitledSection
            title={renderData({ sectionId: 1, columnId: 0, key: "title" })}
            subtitle={renderData({ sectionId: 1, columnId: 0, key: "subtitle" })}
         >
            <p className="mt-4">{renderData({ sectionId: 1, columnId: 0, key: "text" })}</p>

            <section className="mb-5">
               {/* <Tab tabItems={tabs} activeTab={activeTab} className="partner-tab" /> */}

               {/* Cloud Computing Partners */}
               <div className="mt-5">
                  <PartnerHeader header={renderData({ sectionId: 1, columnId: 1, key: "title" })} />
                  <div className="flex justify-content-center row mt-4">
                     <section className="col-lg-6 px-1">
                        <PartnerCard
                           icon={aws}
                           title={renderData({ sectionId: 1, columnId: 2, key: "p_title_1" })}
                           desc={renderData({ sectionId: 1, columnId: 2, key: "p_text_1" })}
                           subhead={renderData({ sectionId: 1, columnId: 2, key: "p_title_2" })}
                           bene1={renderData({ sectionId: 1, columnId: 2, key: "p_text_2a" })}
                           bene2={renderData({ sectionId: 1, columnId: 2, key: "p_text_2b" })}
                           bene3={renderData({ sectionId: 1, columnId: 2, key: "p_text_2c" })}
                        />
                     </section>
                     {/* <section className="col-lg-6 px-1">
                        <TwoListCard
                           icon={oracle}
                           title={renderData({ sectionId: 1, columnId: 3, key: "p_title_1" })}
                           desc={renderData({ sectionId: 1, columnId: 3, key: "p_text_1" })}
                           subhead={renderData({ sectionId: 1, columnId: 3, key: "p_title_2" })}
                           bene={renderData({ sectionId: 1, columnId: 3, key: "p_text_2a" })}
                           bene1={renderData({ sectionId: 1, columnId: 3, key: "p_text_2b" })}
                        />
                     </section> */}
                  </div>
               </div>

               {/* CRM Partners */}
               <div className="mt-5">
                  <PartnerHeader header={renderData({ sectionId: 1, columnId: 4, key: "title" })} />
                  <div className="row mt-4">
                     <section className="col-lg-4 px-1">
                        <OneListCard
                           icon={twilio}
                           title={renderData({ sectionId: 1, columnId: 5, key: "p_title_1" })}
                           desc={renderData({ sectionId: 1, columnId: 5, key: "p_text_1" })}
                           subhead={renderData({ sectionId: 1, columnId: 5, key: "p_title_2" })}
                           bene={renderData({ sectionId: 1, columnId: 5, key: "p_text_2" })}
                        />
                     </section>
                     <section className="col-lg-4 px-1">
                        <PartnerCard
                           icon={zendesk}
                           title={renderData({ sectionId: 1, columnId: 6, key: "p_title_1" })}
                           desc={renderData({ sectionId: 1, columnId: 6, key: "p_text_1" })}
                           subhead={renderData({ sectionId: 1, columnId: 6, key: "p_title_2" })}
                           bene1={renderData({ sectionId: 1, columnId: 6, key: "p_text_2a" })}
                           bene2={renderData({ sectionId: 1, columnId: 6, key: "p_text_2b" })}
                           bene3={renderData({ sectionId: 1, columnId: 6, key: "p_text_2c" })}
                        />
                     </section>
                     <section className="col-lg-4 px-1">
                        <TwoListCard
                           icon={salesforce}
                           title={renderData({ sectionId: 1, columnId: 7, key: "p_title_1" })}
                           desc={renderData({ sectionId: 1, columnId: 7, key: "p_title_1" })}
                           subhead={renderData({ sectionId: 1, columnId: 7, key: "p_text_1" })}
                           bene={renderData({ sectionId: 1, columnId: 7, key: "p_text_2a" })}
                           bene1={renderData({ sectionId: 1, columnId: 7, key: "p_text_2b" })}
                        />
                     </section>
                  </div>
               </div>

               {/* Project Management Partner */}
               <div className="mt-5">
                  <PartnerHeader
                     header={renderData({ sectionId: 1, columnId: 15, key: "title" })}
                  />
                  <div className="row mt-4">
                     <section className="col-lg-10 px-1">
                        <PartnerCard
                           icon={clickup}
                           title={renderData({ sectionId: 1, columnId: 8, key: "p_title_1" })}
                           desc={renderData({ sectionId: 1, columnId: 8, key: "p_text_1" })}
                           subhead={renderData({ sectionId: 1, columnId: 8, key: "p_title_2" })}
                           bene1={renderData({ sectionId: 1, columnId: 8, key: "p_text_2a" })}
                           bene2={renderData({ sectionId: 1, columnId: 8, key: "p_text_2b" })}
                           bene3={renderData({ sectionId: 1, columnId: 8, key: "p_text_2c" })}
                        />
                     </section>
                  </div>
               </div>

               {/* Documents Analytics Partner */}
               <div className="mt-5">
                  <PartnerHeader header={renderData({ sectionId: 1, columnId: 9, key: "title" })} />
                  <div className="row mt-4">
                     <section className="col-lg-10 px-1">
                        <PartnerCard
                           icon={docsend}
                           title={renderData({ sectionId: 1, columnId: 10, key: "p_title_1" })}
                           desc={renderData({ sectionId: 1, columnId: 10, key: "p_text_1" })}
                           subhead={renderData({ sectionId: 1, columnId: 10, key: "p_title_2" })}
                           bene1={renderData({ sectionId: 1, columnId: 10, key: "p_text_2a" })}
                           bene2={renderData({ sectionId: 1, columnId: 10, key: "p_text_2b" })}
                           bene3={renderData({ sectionId: 1, columnId: 10, key: "p_text_2c" })}
                        />
                     </section>
                  </div>
               </div>

               {/* Technology and HR Partners */}
               <div className="mt-5">
                  <PartnerHeader
                     header={renderData({ sectionId: 1, columnId: 11, key: "title" })}
                  />
                  <div className="row mt-4">
                     <section className="col-lg-4 px-1">
                        <NoListCard
                           icon={yebox}
                           title={renderData({ sectionId: 1, columnId: 12, key: "p_title" })}
                           desc={renderData({ sectionId: 1, columnId: 12, key: "p_text" })}
                        />
                     </section>
                     <section className="col-lg-4 px-1">
                        <NoListCard
                           icon={icit}
                           title={renderData({ sectionId: 1, columnId: 13, key: "p_title" })}
                           desc={renderData({ sectionId: 1, columnId: 13, key: "p_text" })}
                        />
                     </section>
                     <section className="col-lg-4 px-1">
                        <NoListCard
                           icon={kandHR}
                           title={renderData({ sectionId: 1, columnId: 14, key: "p_title" })}
                           desc={renderData({ sectionId: 1, columnId: 14, key: "p_text" })}
                        />
                     </section>
                     <section className="col-lg-10 px-1">
                        <PartnerCard
                           icon={firstbase}
                           title={renderData({ sectionId: 1, columnId: 16, key: "p_title_1" })}
                           desc={renderData({ sectionId: 1, columnId: 16, key: "p_text_1" })}
                           subhead={renderData({ sectionId: 1, columnId: 16, key: "p_title_2" })}
                           bene1={renderData({ sectionId: 1, columnId: 16, key: "p_text_2a" })}
                           bene2={renderData({ sectionId: 1, columnId: 16, key: "p_text_2b" })}
                           bene3={renderData({ sectionId: 1, columnId: 16, key: "p_text_2c" })}
                           bene4={renderData({ sectionId: 1, columnId: 16, key: "p_text_2d" })}
                           bene5={renderData({ sectionId: 1, columnId: 16, key: "p_text_2e" })}
                        />
                     </section>
                  </div>
               </div>
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our partner program today!" />
         </section>
      </div>
   );
});
