import React, { useEffect } from "react";
import {
   Banner,
   TitledSection,
   WithLayout,
   // PersonCard,
   InfoCard,
   GetStartedCard
} from "../components";
import banner from "../assets/images/mentorBanner.png";
// import sampleFin from "../../assets/images/sampleFinMentors.png";

export const SignIn = WithLayout(() => {
   useEffect(() => {
      window.location.href = "https://app.knight.ventures/";
   }, []);

   return (
      <div>
         <section>
            <Banner background={banner} mainText="Page Not Found" />
         </section>

         <section className="get-started-space">
            <GetStartedCard text="Join our program today!" />
         </section>
      </div>
   );
});
