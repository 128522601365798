import React from "react";
import { Button } from "..";
import "./getStartedCard.css";
import { useLocation } from "react-router-dom";

export const GetStartedCard = ({ text = "", action = () => {} }) => {
   const { pathname } = useLocation();
   let redirectPath = pathname.split("/")[1];

   return (
      <div className="started-card flex-wrap">
         <p>{text}</p>
         <Button
            label="Get Started"
            onClick={() => {
               window.open(
                  `https://app.knight.ventures/sign-up-${
                     redirectPath === "accelerator" || "about" ? "startup" : redirectPath
                  }`
               );
            }}
         />
      </div>
   );
};
